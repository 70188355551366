<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useProvider } from '/~/composables/provider'
import PointsPartnerPlaceholder from '../../components/points-partner-placeholder.vue'
import PartnersAvailableList from '../components/partners-list.vue'

export default {
  name: 'fly-partners-available-mobile',
  components: {
    PartnersAvailableList,
    PointsPartnerPlaceholder,
    BaseAsidePage,
  },
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { providerTitle } = useProvider()

    return {
      providerTitle,
    }
  },
}
</script>

<template>
  <base-aside-page
    title="Airline Partners"
    :back="{ name: 'home' }"
    shadow
    no-padding
  >
    <div class="bg-eonx-neutral-50 px-5 py-6">
      <div class="mb-8">
        <h2 class="text-lg">Transfer your {{ providerTitle }} Points</h2>
        <p class="mt-2 text-eonx-neutral-600">
          Choose your preferred rewards program from participating airline
          partners and transfer your {{ providerTitle }} Points with ease
        </p>
      </div>
      <points-partner-placeholder v-if="loading" :rows="3" />
      <partners-available-list v-else :partners="partners" />
    </div>
  </base-aside-page>
</template>
