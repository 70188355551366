<script>
import ui from '/~/core/ui'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import PartnersListDesk from './partners-list.desk.vue'
import PartnersListMobile from './partners-list.mobile.vue'

export default {
  name: 'fly-partner-add',
  components: {
    PartnersListDesk,
    PartnersListMobile,
  },
  setup() {
    const { accounts, fetchAccountsLoading, partners, fetchAccounts } =
      usePointsPrograms()

    if (!accounts.value.length) {
      fetchAccounts()
    }

    return {
      accounts,
      fetchAccountsLoading,
      partners,
      ui,
    }
  },
  computed: {
    partnersWithCounter() {
      this.partners?.forEach((partner) => {
        let lists = []

        if (this.accounts.length) {
          lists = this.accounts.filter(
            (account) => account.type === partner.type
          )
        }
        const count = lists.length

        partner.count = count
        partner.accounts = lists
      })

      return this.partners
    },
    component() {
      return !ui.mobile ? PartnersListDesk : PartnersListMobile
    },
    initial() {
      return this.$route.params.state === 'initial'
    },
  },
}
</script>

<template>
  <component
    :is="component"
    :initial="initial"
    :partners="partnersWithCounter"
    :loading="fetchAccountsLoading"
  />
</template>
